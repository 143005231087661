<template>
  <div class="user-aside-menu">
    <UserPersonalOptions :_view="_view" :modal="modal" />
  </div>
</template>

<script>
export default {
  props: ["_view", "modal"],
  components: {
    UserPersonalOptions: () => import("./UserPersonalOptions.vue"),
  },
};
</script>
